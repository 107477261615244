<template>
  <a-modal :maskClosable="false" title="选择需求单商品" style="top: 8px;" width="80%" v-model="visible" @ok="toSubmit">
    <div class="query">
      <a-form-model :labelCol="{span: 7}" :wrapperCol="{span:17}">
        <a-row :gutter="4">
          <!-- <a-col :md="8">
            <a-form-model-item label="物料编号">
              <a-input v-model.trim="queryParam.goodsCode" placeholder="商品名称" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8">
            <a-form-model-item label="编码名称">
              <a-input v-model.trim="queryParam.cusCodeName" placeholder="请输入经销商编码名称" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8">
            <a-form-model-item label="图号">
              <a-input v-model="queryParam.chartCount" placeholder="请输入图号" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col> -->
          <!-- <a-col :md="8">
            <a-form-model-item label="车型描述">
              <a-input v-model.trim="queryParam.name" placeholder="请输入车型描述" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col> -->
          <!-- <a-col :md="8">
            <a-form-model-item label="OE码">
              <a-input v-model.trim="queryParam.oeCode" placeholder="请输入OE码" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8">
            <a-form-model-item label="排量">
              <a-select v-model="carDisplac" style="width: 100%" placeholder="请选择" @change="handleChange">
                <a-select-option :value="item.id" v-for="(item, index) in displacement" :key="index">{{item.title}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <a-col :md="8">
            <a-form-model-item label="商品名称">
              <a-input v-model.trim="queryParam.productName" placeholder="请输入商品名称" :max-length="50"></a-input>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :md="8">
            <a-form-model-item label="商品分类">
              <a-cascader :options="categoryList" v-model="categoryValue" :field-names="{ label: 'name', value: 'id', children: 'children' }" placeholder="请选择" @change="onCategoryChange" />
            </a-form-model-item>
          </a-col>
          <a-col :md="8">
            <a-form-model-item label="车信息">
              <a-cascader :options="options" v-model="carinfo" :field-names="{ label: 'name', value: 'id', children: 'children' }" placeholder="请选择" @change="onSelectChange" />
            </a-form-model-item>
          </a-col> -->
          <a-col :md="8">
            <a-form-model-item :labelCol="{span: 0}" :wrapperCol="{span:22,offset:1}">
              <a-button @click="toSearch(0)" type="primary" icon="search">查询</a-button>
              <a-button @click="toSearch(1)" type="default" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <div class="table">
      <a-table :data-source="tableData" bordered rowKey="goodsId" :pagination="false"
        :row-selection="{fixed: true,type: 'checkbox',selectedRowKeys:selectedRowKeys,onChange:
          (keys,rows)=>{selectedRowKeys=keys;selectedRows= rows}}"
        :customRow="record=>{return{on:{click:e=>{let index=this.selectedRowKeys.indexOf(record.goodsId);index>=0?selectedRowKeys.splice(index, 1):selectedRowKeys.push(record.goodsId);index>=0?selectedRows.splice(index, 1):selectedRows.push(record);}}}}">
        <a-table-column title="CNC商号" data-index="cncCompany" :width="100" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="商品名称" data-index="goodsName" :width="300" :ellipsis="true" align="left">
          <template slot-scope="text, row">
            <a-tag color="#f50" v-if="row.activityInfo != null && row.activityInfo.type == 2">特</a-tag>
            {{row.goodsName}}
          </template>
        </a-table-column>
        <a-table-column title="规格型号" data-index="specStr" :width="150" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="总成号" data-index="assemblyNumber" :width="120" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="适用品牌" data-index="carBrand" :width="150" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="适用车型" data-index="carModel" :width="150" :ellipsis="true" align="left">
        </a-table-column>

        <!-- <a-table-column title="商品分类" data-index="categoryName" :width="200" :ellipsis="true" align="left">
        </a-table-column> -->
        <a-table-column title="商品图片" data-index="goodsImg" :width="150" :ellipsis="true" align="left">
          <template slot-scope="text, record">
            <ZoomMedia style="height: 20px;" :value="text"></ZoomMedia>
          </template>
        </a-table-column>
        <a-table-column title="价格" data-index="price" :width="120" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="优惠价格" data-index="activityList" :width="120" :ellipsis="true" align="left">
          <template slot-scope="text, row">
            <span v-if="row.activityInfo != null"> {{row.price - row.activityInfo.activityPrice}} </span>
            <span v-else> {{row.price - row.price}} </span>
          </template>
        </a-table-column>
        <a-table-column title="促销价" data-index="activityPrice" :width="120" :ellipsis="true" align="left">
          <template slot-scope="text, row">
            <span v-if="row.activityInfo != null"> {{row.activityInfo.activityPrice}} </span>
            <span v-else>{{row.price}} </span>
          </template>
        </a-table-column>
        <a-table-column title="需求单数量" data-index="demandNum" :width="120" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="已发数量" data-index="sendNum" :width="120" :ellipsis="true" align="left">
        </a-table-column>
        <a-table-column title="剩余数量" data-index="allowCreatedNum" :width="120" :ellipsis="true" align="left">
        </a-table-column>
        
        <!-- <a-table-column title="车系" data-index="carSeries" :width="150" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="排量" data-index="carDisplacement" :width="150" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="图号" data-index="chartCount" :width="150" :ellipsis="true" align="center">
        </a-table-column>
        <a-table-column title="OE码" data-index="oeCode" :width="150" :ellipsis="true" align="center">
        </a-table-column> -->
      </a-table>
    </div>
  </a-modal>
</template>
<script>
  const qs = require('qs')
  export default {
    data() {
      return {
        queryParam: {},
        resetQueryParam: null,
        tableData: [],
        loading: false,
        page: {
          current: 1,
          pageSize: 999,
          total: 0
        },
        selectedRowKeys: [],
        selectedRows: [],
        visible: false,
        demandId:0,
        options: [],
        carinfo: [],
        categoryList: [],
        categoryValue: [],
        displacement: [],
        carDisplac: []
      }
    },
    components: {},
    methods: {

      show(ids,demandId) {
        this.visible = true
        this.selectedRowKeys = ids
        this.demandId = demandId
        this.selectedRows=[]
        this.toSearch(1)
        this.axios.get('/api/product/car/carBaseInfo/list?flagEnable=true').then(res => {
          this.options = res.body[0] ? res.body[0].children : []
        })
        this.axios.get('/api/product/category/list?flagEnable=true').then(res => {
          this.categoryList = res.body[0] ? res.body[0].children : []
        })
        this.axios.get('/api/base/system/dictionary/selectByCode/排量').then(res => {
          this.displacement = res.body
        })
      },
      toSearch(type) {
        if (type == 1) {
          this.resetQueryParam ? this.queryParam = Object.assign({}, this.resetQueryParam) : this.resetQueryParam =
            Object.assign({}, this.queryParam)
            this.carinfo = []
            this.categoryValue = []
            this.carDisplac = []
        }
        const postData = Object.assign(this.queryParam, {
          demandId: this.demandId,
          pageNumber: type == 2 ? this.page.current : this.page.current = 1,
          pageSize: this.page.pageSize
        })
        this.axios.get(`/api/order/system/orderDemand/listDemandProduct?${qs.stringify(postData)}`).then(res => {
          let tmpArr=res.body.records
          this.tableData = res.body.records
          this.selectedRows=tmpArr.filter(x=>this.selectedRowKeys.findIndex(y=>y==x.goodsId)>=0)
          this.page.total = res.body.total
        }).catch(err => {})
      },
      toSubmit() {
        if(this.selectedRows.length <= 0) {
          this.$message.warning('请至少选择一条商品')
          return
        }
        this.$emit('select', this.selectedRows)
        this.visible = false
      },
      onSelectChange(value) {
        this.queryParam.carBrandId = value ? value[0] : ''
        this.queryParam.carSeriesId = value ? value[1] : ''
        this.queryParam.carModelId = value ? value[2] : ''
      },
      onCategoryChange(value) {
        var reg = new RegExp(",","g")
        this.queryParam.categoryIdStr = value.map(i => {return i}).toString().replace(reg, '_')
      },
      handleChange(value) {
        this.queryParam.carDisplacementId = value
      }
    },
    created() {

    },

  }
</script>


<style lang="less" scoped>
</style>
